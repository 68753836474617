.topnav{
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 60px;
  background: #F5F5F5 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  }

.Logo{
position: absolute;
top: 10px;
left: 24px;
width: 128px;
height: 41px;
background: transparent url('./Logo-2.png') 0% 0% no-repeat padding-box;
opacity: 1;
}

.CartLogo{
position: absolute;
top: 19px;
left: 1811px;
width: 24px;
height: 23px;
background: transparent url('./shopping-cart.png') 0% 0% no-repeat padding-box;
opacity: 1;
}

.profileLogo{
position:absolute;
top: 18px;
left: 1870px;
width: 24px;
height: 24px;
background: transparent url('./noun_profile_2068277.png') 0% 0% no-repeat padding-box;
opacity: 1;
}

.Courses{
text-align: left;
font: normal normal bold 16px/19px Montserrat;
letter-spacing: 0px;
color: #080808;
opacity: 1;
}

.wishList{
text-align: left;
font: normal normal 300 16px/19px Montserrat;
letter-spacing: 0px;
color: #080808;
opacity: 1;
}

a{
  text-decoration: none;
  list-style-type: none;
  display: inline-block;
}
.course{
position: absolute;
top: 21px;
left: 1507px;
width: 81px;
height: 19px;
}

.wishlist{
  position: absolute;
  top: 21px;
  left: 1633px;
  width: 107px;
  height: 19px;
}

.Banner{
position: absolute;
top: 76px;
left: 140px;
width: 1640px;
height: 111px;
background: #0F1317 0% 0% no-repeat padding-box;
border-radius: 4px;
opacity: 1;
}
.bannerTitle{
position: absolute;
top: 23px;
left: 130px;
width: 398px;
height: 76px;
text-align: left;
font: normal normal 600 20px/24px Montserrat;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.bannerImage{
position: absolute;
top: 0 px;
left: 907px;
width: 723px;
height: 111px;
background: transparent url('./Mask Group 1.png') 0% 0% no-repeat padding-box;
opacity: 1;
}
.header1{
position: absolute;
top: 229px;
left: 157px;
width: 129px;
height: 20px;
text-align: left;
font: normal normal 600 20px/24px Montserrat;
letter-spacing: 0px;
color: #0F1317;
opacity: 1;
}

.sorting{
position: absolute;
top: 260px;
left: 1184px;
width: 182px;
height: 40px;
opacity: 1;
}

.sort{
  width: 182px;
}
.search-container{
position: absolute;
top: 260px;
left: 1382px;
width: 398px;
height: 40px;
}
.cart{
position: absolute;
top: 295px;
left: 1382px;
width: 378px;
height: 550px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
opacity: 1;
}

.cartHeader{
text-align: center;
font: normal normal 600 18px/22px Montserrat;
letter-spacing: 0px;
color: #080808;
opacity: 1;
}
.cartBackdropText{
position: absolute;
top: 40px;
left: 40px;
width: 343px;
height: 38px;
text-align: left;
font: italic normal medium 16px/19px Montserrat;
letter-spacing: 0px;
color: #AAAAAA;
opacity: 1;
}

.cartFooter{
position: absolute;
top: 450px;
left: 30px;
width: 130px;
height: 19px;
text-align: left;
font: normal normal medium 16px/19px Montserrat;
letter-spacing: 0px;
color: #080808;
opacity: 1;
}

.TotalValue{
position: absolute;
top: 5px;
text-align: left;
font: normal normal bold 28px/34px Montserrat;
letter-spacing: 0px;
color: #080808;
opacity: 1;
}

.card{
position: absolute;
top: 295px;
left: 140px;
width: 1226px;
height: 100px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #F4F4F4;
border-radius: 0px 0px 4px 4px;
opacity: 1;
}
.card1{
  position: absolute;
  top: 405px;
  left: 140px;
  width: 1226px;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #F4F4F4;
  border-radius: 0px 0px 4px 4px;
  opacity: 1;
  }
  .card2{
    position: absolute;
    top: 515px;
    left: 140px;
    width: 1226px;
    height: 100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
    }
    .card3{
      position: absolute;
      top: 625px;
      left: 140px;
      width: 1226px;
      height: 100px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #F4F4F4;
      border-radius: 0px 0px 4px 4px;
      opacity: 1;
      }
      .card4{
        position: absolute;
        top: 745px;
        left: 140px;
        width: 1226px;
        height: 100px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #F4F4F4;
        border-radius: 0px 0px 4px 4px;
        opacity: 1;
        }
        .courseImage{
        position: absolute;
        top: 10px;
        left: 10px;
        width: 79px;
        height: 70px;
        background: #F2F2F2 0% 0% no-repeat padding-box;
        opacity: 1;
        }
        .courseTitle{
        position: absolute;
        top: 0 px;
        left: 108px;
        width: 335px;
        height: 44px;
        text-align: left;
        font: normal normal 600 18px/22px Montserrat;
        letter-spacing: 0px;
        color: #080808;
        opacity: 1;
        }

        .courseInstructor{
          position: absolute;
          top: 13px;
          left: 650px;
          width: 117px;
          height: 19px;
          text-align: left;
          font: normal normal 300 16px/19px Montserrat;
          letter-spacing: 0px;
          color: #080808;
          opacity: 1;
        }

        .coursePrice{
          position: absolute;
          top: 13px;
          left: 850px;
          width: 106px;
          height: 24px;
          text-align: left;
          font: normal normal bold 20px/24px Montserrat;
          letter-spacing: 0px;
          color: #080808;
          opacity: 1;
        }
        .AddToCart{
          position: absolute;
          top: 30px;
          left: 1056px;
          width: 145px;
          height: 40px;
          text-align: left;
          font: normal normal 600 16px/19px Montserrat;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;
          background: #FF6738 0% 0% no-repeat padding-box;
          border-radius: 4px;
          opacity: 1;
        }

        .WishListIcon{
          position: absolute;
          border: none;
          top: 37px;
          left: 800px;
          width: 26px;
          height: 25px;
          background: transparent url('./Path 28.png') 0px 0px no-repeat padding-box;
          opacity: 1;
        }
        .initialPrice{
          position: absolute;
          top: 20px;
          left: 980px;
          width: 62px;
          height: 19px;
          text-align: left;
          text-decoration: line-through;
          font: normal normal 300 16px/19px Montserrat;
          letter-spacing: 0px;
          color: #080808;
          opacity: 1;
        }
        .PageNo{
          position: absolute;
          top: 870px;
          left: 140px;
          width: 109px;
          height: 20px;
          opacity: 1;
        }